import React from 'react'
import PropTypes from 'prop-types'

import { useAppContext } from '../../context/AppContext'
import AllResults from '../components/search/AllResults'

import styled, { css } from 'styled-components'

const Page = ({ children, noPaddingTop }) => {
  const { menuModalOpen, showAllSearchResults } = useAppContext()

  return (
    <>
      <Main
        noPaddingTop={noPaddingTop}
        style={{
          display: menuModalOpen || showAllSearchResults ? 'none' : 'block',
        }}
      >
        {children}
      </Main>
      {showAllSearchResults && <AllResults />}
    </>
  )
}

Page.propTypes = {
  children: PropTypes.node,
  footer: PropTypes.object,
  header: PropTypes.object,
  noPaddingTop: PropTypes.bool,
}

export default Page

const Main = styled.main`
  ${({ noPaddingTop }) =>
    noPaddingTop &&
    css`
      > section:first-child {
        padding-top: 0;
      }
    `};
`
