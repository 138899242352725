import React, { useCallback, useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Text, { setFontSize } from '../../common/Text'
import HTMLParser from 'react-html-parser'
import { useAppContext } from '../../../context/AppContext'
import { Row } from 'react-flexa'
import LinkComponent from '../../common/Link'

import { chunk, get } from 'lodash'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { format } from 'date-fns'
import theme from '../../../style/theme'
import Section from '../../common/Section'
import Pagination from '../../common/Pagination'

const gutters = {
  xs: rem(80),
  sm: rem(80),
  md: rem(80),
  lg: rem(80),
  xl: rem(80),
  xxl: rem(90),
}

const ResultItem = ({ fields, ...props }) => {
  const date = get(fields, 'publish_date')
  const {
    setShowAllSearchResults,
    setMenuModalOpen,
    setShowNavSearch,
  } = useAppContext()

  const handleClick = useCallback(() => {
    setShowAllSearchResults(false)
    setMenuModalOpen(false)
    setShowNavSearch(false)
  }, [setMenuModalOpen, setShowAllSearchResults, setShowNavSearch])

  return (
    <LinkComponent href={fields.path} onClick={handleClick}>
      <ResultItemContainer {...props}>
        <ResultHeaderRow
          hasDate={!!date}
          justifyContent="space-between"
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Text.h5 color={theme.color.secondaryBlue}>{fields.title}</Text.h5>
          {date && (
            <DateText
              book
              size="xxs"
              lg="sm"
              uppercase={false}
              color="#4A5D7C"
              opacity={0.75}
            >
              Posted {format(new Date(date), "MMM d',' yyyy").toUpperCase()}
            </DateText>
          )}
        </ResultHeaderRow>
        <Text.p>{HTMLParser(fields.excerpt)}</Text.p>
        <SearchLink href={fields.path} onClick={handleClick}>
          <Text.span
            lineHeight={rem(18)}
            lineHeightLg={rem(20)}
            book
            size="xxs"
            lg="xs"
            color={theme.color.blue}
            spacing={0}
          >
            {fields.url.replace(/https?:\/\//, '')}
          </Text.span>
        </SearchLink>
      </ResultItemContainer>
    </LinkComponent>
  )
}

ResultItem.propTypes = {
  fields: PropTypes.object,
}

const goToTheTop = () => {
  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0)
  }
}

const AllResults = ({ toggleShowAll }) => {
  const { currentSearchResults, currentSearch, isLarge } = useAppContext()
  const [currentPage, setCurrentPage] = useState(0)
  const pages = useMemo(() => chunk(currentSearchResults, 10), [
    currentSearchResults,
  ])

  const onNext = useCallback(() => {
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1)
      goToTheTop()
    }
  }, [currentPage, pages.length])

  const onPrev = useCallback(() => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1)
      goToTheTop()
    }
  }, [currentPage])

  useEffect(() => {
    goToTheTop()
  }, [])

  return (
    <Container>
      <Section as="div" fullWidth={!isLarge} noPaddingTop>
        <Content>
          <HeaderRow flexDirection="column" alignItems="center">
            <Text.h5>
              SHOWING {currentSearchResults.length} SEARCH RESULTS FOR
            </Text.h5>
            <Text.h3 bold>“{currentSearch}”</Text.h3>
            <HR />
          </HeaderRow>
          <ResultsRow gutter={gutters}>
            {pages[currentPage].map((entry, i) => (
              <ResultItem
                key={entry.id + i}
                {...entry}
                xs={12}
                lg={6}
                gutter={gutters}
                i={i}
              />
            ))}
          </ResultsRow>
        </Content>
      </Section>
      {pages.length > 1 && (
        <Section noPaddingTop>
          <Pagination
            onNext={onNext}
            onPrev={onPrev}
            current={currentPage}
            total={pages.length}
            fill={theme.color.yellow}
          />
        </Section>
      )}
    </Container>
  )
}

AllResults.propTypes = {
  toggleShowAll: PropTypes.func,
}

AllResults.defaultProps = {
  results: [],
}

export default AllResults

const Content = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: ${theme.space(3)} ${theme.space(4)} 0;
    max-width: ${theme.layout.maxWidth};

    ${theme.media.lg`
       padding: ${theme.space(2)} 0 0 0;
    `};
  `};
`

const DateText = styled(Text)`
  ${({ theme }) => css`
    margin-top: ${theme.space(1)};

    ${theme.media.md`
      margin-top: 0;
    `};
  `};
`

const ResultHeaderRow = styled(Row)`
  ${({ theme, hasDate }) => css`
    margin-bottom: ${rem(10)};

    ${theme.media.lg`
      margin-bottom: ${rem(5)};

      ${hasDate &&
        css`
          a {
            width: calc(100% - 180px);
          }
        `}
    `}

    h5 {
      display: inline-flex;
      align-self: flex-start;
      letter-spacing: 0 !important;
      line-height: ${rem(23)} !important;

      ${theme.media.md`
        max-width: 75%;
      `};

      ${theme.media.xl`
        max-width: 60%;
      `};
    }
  `}
`

const ResultItemContainer = styled.div`
  ${({ theme, i }) => css`
    opacity: 0;
    ${theme.mixin.fadeIn(i * 0.05)};
    margin-bottom: ${rem(45)};
    width: 100%;

    ${theme.media.lg`
      margin-bottom: ${rem(40)};
    `}

    h5 {
      transition: 0.3s color;
    }

    &:hover {
      h5 {
        color: ${theme.color.blue};
      }
    }
  `}
`

const SearchLink = styled(LinkComponent)`
  ${({ theme }) => css`
    display: block;

    > ${Text.span} {
      transition: all 0.3s;
    }

    margin-top: ${theme.space(1.5)};

    > ${Text.span} {
      cursor: pointer;
      transition: 0.3s color;

      &:hover {
        color: ${theme.color.blue};
      }
    }
  `}
`

export const HR = styled.div`
  ${({ theme }) => css`
    height: 1px;
    width: 100%;
    position: relative;
    background: ${theme.color.secondaryLightBlue};
  `}
`

const HeaderRow = styled(Row)`
  ${({ theme }) => css`
    width: 100%;

    ${HR} {
      margin-top: ${theme.space(2)};

      ${theme.media.lg`
        margin-top: ${theme.space(4)};
      `};
    }
  `}
`

const ResultsRow = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: ${theme.space(3)};

    h5 {
      ${setFontSize(13)};
      letter-spacing: ${rem(1)};
    }

    ${theme.media.lg`
      margin-top: ${theme.space(5)};
    `};

    ${theme.media.xl`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: ${theme.space(0)} ${theme.space(9)};
    `};
  `}
`

const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: 100vh;
    background: ${theme.color.softBlue};

    > section > div {
      width: 100%;
      max-width: ${theme.layout.maxWidth};

      ${theme.media.lg`
        margin-top: ${theme.layout.navHeight.lg}px;
      `}

      ${theme.media.xl`
        margin-top: 0;
      `}
    }
  `}
`
