import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { rem } from 'polished'
import { get } from 'lodash'

const Section = styled.section`
  ${({
    bgColor,
    center,
    fullWidth,
    fullWidthSmall,
    fullWidthMedium,
    noPaddingBottom,
    noPaddingTop,
    theme,
    verticalPadding,
    verticalPaddingMenu,
  }) => css`
    position: relative;
    width: 100%;
    padding-left: ${rem(10)};
    padding-right: ${rem(10)};
    background-color: ${get(theme.color, bgColor) || bgColor};

    ${center &&
      css`
        display: flex;
        justify-content: center;
      `};

    ${verticalPadding && theme.layout.verticalPadding};
    ${verticalPaddingMenu && theme.layout.verticalPaddingMenu};

    ${noPaddingBottom &&
      css`
        padding-bottom: 0;

        ${theme.media.lg`
          padding-bottom: 0;
        `};
      `};

    ${noPaddingTop &&
      css`
        padding-top: 0;

        ${theme.media.lg`
          padding-top: 0;
        `};
      `};

    ${(fullWidthSmall || fullWidthMedium) &&
      css`
        padding-left: 0;
        padding-right: 0;
      `};

    ${theme.media.md`
      padding-left: ${rem(45)};
      padding-right: ${rem(45)};
    `};

    ${fullWidthMedium &&
      css`
        ${theme.media.md`
        padding-left: 0;
        padding-right: 0;
        `};

        ${theme.media.lg`
          padding-left: ${rem(45)};
          padding-right: ${rem(45)};
        `};
      `};

    ${fullWidth &&
      css`
        padding-left: 0;
        padding-right: 0;

        ${theme.media.md`
          padding-left: 0;
          padding-right: 0;
        `};
      `};
  `};
`

Section.defaultProps = {
  bgColor: 'transparent',
  center: true,
  verticalPadding: true,
}

Section.propTypes = {
  bgColor: PropTypes.string,
  center: PropTypes.bool,
  fullWidth: PropTypes.bool,
  fullWidthMedium: PropTypes.bool,
  fullWidthSmall: PropTypes.bool,
  noPaddingBottom: PropTypes.bool,
  noPaddingTop: PropTypes.bool,
  verticalPadding: PropTypes.bool,
}

const calcPaddingValue = (value, padding) => {
  if (!isNaN(value)) {
    return rem(value * padding)
  } else if (typeof value === 'string') {
    return value
  }
}

export const SliceSection = styled(Section)`
  ${({ theme, paddingProps }) => css`
    padding-top: ${calcPaddingValue(
      paddingProps.top,
      theme.layout.slicePadding.xs,
    )};
    padding-bottom: ${calcPaddingValue(
      paddingProps.bottom,
      theme.layout.slicePadding.xs,
    )};

    ${theme.media.md`
      padding-top: ${calcPaddingValue(
        paddingProps.top,
        theme.layout.slicePadding.md,
      )};
      padding-bottom: ${calcPaddingValue(
        paddingProps.bottom,
        theme.layout.slicePadding.md,
      )};
    `}

    ${theme.media.xl`
      padding-top: ${calcPaddingValue(
        paddingProps.top,
        theme.layout.slicePadding.xl,
      )};
      padding-bottom: ${calcPaddingValue(
        paddingProps.bottom,
        theme.layout.slicePadding.xl,
      )};
    `}
  `}
`
SliceSection.propTypes = {
  paddingProps: PropTypes.object,
}

SliceSection.defaultProps = {
  verticalPadding: false,
  paddingProps: {
    top: 0.5,
    bottom: 0.5,
  },
}

export default Section
