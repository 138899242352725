import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Row } from 'react-flexa'
import { PrevNextArrow } from './Icons'
import Text from './Text'
import { rem } from 'polished'
import { useAppContext } from '../../context/AppContext'

const DirectionButton = ({ onClick, prev, disabled, fill }) => {
  const { isMedium } = useAppContext()
  return (
    <DirectionButtonContainer prev={prev} disabled={disabled} onClick={onClick}>
      {!prev && isMedium && <Text.h5>NEXT PAGE</Text.h5>}
      {prev ? <PrevButton fill={fill} /> : <NextButton fill={fill} />}
      {prev && isMedium && <Text.h5>PREV PAGE</Text.h5>}
    </DirectionButtonContainer>
  )
}

DirectionButton.propTypes = {
  onClick: PropTypes.func,
  prev: PropTypes.bool,
  disabled: PropTypes.bool,
  fill: PropTypes.string,
}

const Pagination = ({ fill, onNext, onPrev, current, total, iconColor }) => {
  return (
    <PaginationRow justifyContent="space-between" iconColor={iconColor}>
      <DirectionButton
        fill={fill}
        prev
        disabled={current === 0}
        onClick={onPrev}
      />
      <Text>
        Page {current + 1} of {total}
      </Text>
      <DirectionButton
        fill={fill}
        disabled={current + 1 === total}
        onClick={onNext}
      />
    </PaginationRow>
  )
}

Pagination.propTypes = {
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  current: PropTypes.number,
  total: PropTypes.number,
  iconColor: PropTypes.string,
  fill: PropTypes.string,
}

Pagination.defaultProps = {
  fill: '#fff',
}

export default Pagination

const PaginationRow = styled(Row)`
  ${({ theme, iconColor }) => css`
    flex: 1;
    svg path {
      fill: ${iconColor};
    }
  `}
`

const DirectionButtonContainer = styled.div`
  ${({ theme, prev, disabled }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;

    svg * {
      transition: 0.3s fill;
    }

    &:hover {
      svg * {
        fill: ${theme.color.blue};
      }
    }

    ${disabled &&
      css`
        pointer-events: none;
        opacity: 0.25;
        cursor: auto;
      `}

    h5 {
      ${prev
        ? css`
            margin-left: ${rem(10)};
          `
        : css`
            margin-right: ${rem(10)};
          `};
    }
  `}
`

const NextButton = styled(PrevNextArrow)`
  ${({ theme }) => css`
    height: ${rem(24)};
    width: ${rem(24)};
    position: relative;
  `}
`

const PrevButton = styled(NextButton)`
  ${({ theme }) => css`
    transform: scaleX(-1);
  `}
`
