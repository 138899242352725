import { mergePrismicPreviewData } from 'gatsby-source-prismic'
const IS_BROWSER = typeof window !== 'undefined'

const usePreviewData = staticDataJson => {
  const staticData =
    typeof staticDataJson === 'string'
      ? JSON.parse(staticDataJson)
      : staticDataJson

  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__
  const data = mergePrismicPreviewData({ staticData, previewData })
  return data
}

export default usePreviewData
